import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SignUp from "../components/SignUp"
import FAQITEM from "../constant/faqItem"
import { makeStyles } from "@material-ui/core/styles"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Popover,
  Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import queryString from "query-string"
import FAQVideo from "../videos/INVOKEINSIGHTSFAQs.mp4"
import IntroVideo from "../videos/INVOKEINSIGHTSIntro.mp4"
import ReportVideo from "../videos/INVOKEINSIGHTSReports.mp4"
import StepVideo from "../videos/INVOKEInsightsStepByStep.mp4"
import FAQPoster from "../images/FAQPoster.png"
import IntroPoster from "../images/IntroPoster.png"
import ReportPoster from "../images/ReportsPoster.png"
import StepPoster from "../images/StepsPoster.png"
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 700,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(5),
  },
}))

const points = {
  pre: `<u>Pre-enrollment</u> can be utilized before a production ever begins and allows creators to complete a portion of the form and save their information for future productions using their phone number. This feature is currently in development and will be launched very soon.`,
  onboard: `<u>Onboarding on set</u> can be utilized during the production and allows for specialists to arrive with COVID officers on set at the beginning of one or multiple shoot days. This option allows for creators to ask questions and get more information about Invoke as they complete the form.`,
  outreach: `<u>Retroactive Outreach</u> can be utilized after a production has wrapped their set days and/or during post-production. This option allows specialists to contact creators virtually with a unique form link. Additionally, representatives can be made available for introductory calls for more information on the platform and access to virtual explainer materials.`,
}

const FAQ = props => {
  const classes = useStyles()
  const [video, setVideo] = useState("faq")
  const [poster, setPoster] = useState("faq")
  const [anchorEl, setAnchorEl] = useState(null)
  const [text, setText] = useState("")
  const [currentOpen, setCurrentOpen] = useState("")
  const { search } = props.location
  const { dashboard } = queryString.parse(search)

  const handlePopoverOpen = (event, type) => {
    if (type === "pre") {
      setText(points.pre)
    } else if (type === "onboard") {
      setText(points.onboard)
    } else {
      setText(points.outreach)
    }

    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const onClickVideo = selectedVideo => {
    const theVid = document.getElementById("vid")
    const videoSource = document.getElementById("source")

    setVideo(selectedVideo)
    setPoster(selectedVideo)

    theVid.pause()

    videoSource.setAttribute(
      "src",
      selectedVideo === "faq"
        ? IntroVideo
        : selectedVideo === "intro"
        ? StepVideo
        : selectedVideo === "report"
        ? ReportVideo
        : FAQVideo
    )

    theVid.load()
    theVid.play()
  }

  return (
    <Layout>
      <Seo title="FAQ - INVOKE by FREE THE WORK" />
      <div className="faq">
        <div className="video-wrapper">
          <div className="container">
            <div className="flex">
              <div className="video-container">
                <video
                  id="vid"
                  controls
                  poster={
                    video === "faq"
                      ? FAQPoster
                      : video === "intro"
                      ? IntroPoster
                      : video === "report"
                      ? ReportPoster
                      : StepPoster
                  }
                >
                  <source
                    id="source"
                    src={
                      poster === "faq"
                        ? IntroVideo
                        : video === "intro"
                        ? StepVideo
                        : video === "report"
                        ? ReportVideo
                        : FAQVideo
                    }
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="video-list">
                <h4>Click below to hear about Invoke Insights</h4>
                <p
                  className={video === "faq" ? "active" : "not-active"}
                  onClick={() => {
                    onClickVideo("faq")
                  }}
                >
                  Video 1: What is Invoke?
                </p>
                <p
                  className={video === "intro" ? "active" : "not-active"}
                  onClick={() => {
                    onClickVideo("intro")
                  }}
                >
                  Video 2: How to use the platform?
                </p>
                <p
                  className={video === "report" ? "active" : "not-active"}
                  onClick={() => {
                    onClickVideo("report")
                  }}
                >
                  Video 3: Impact Reporting
                </p>
                <p
                  className={video === "step" ? "active" : "not-active"}
                  onClick={() => {
                    onClickVideo("step")
                  }}
                >
                  Video 4: Frequently Asked Questions
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container answer">
          <h1>INVOKE INSIGHTS FAQs</h1>

          <h3>For Hirers</h3>
          {FAQITEM[0].forHires.slice(0, 4).map((item, index) => (
            <Accordion
              key={index}
              onClick={() => setCurrentOpen(item.id)}
              expanded={item.id === currentOpen}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${index}-content`}
                id={index}
              >
                <Typography className={classes.heading}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p dangerouslySetInnerHTML={{ __html: item.description }} />

                {item.points.length > 0 &&
                  item.points.map((i, index) => (
                    <ul key={index}>
                      <li dangerouslySetInnerHTML={{ __html: i.item }} />
                    </ul>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}

          <Accordion
            key="5"
            onClick={() => setCurrentOpen(5)}
            expanded={5 === currentOpen}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`next-production-content`}
              id={`next-production-content`}
            >
              <Typography className={classes.heading}>
                How can Invoke be a part of my next production?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p>
                Invoke operates in a couple of different ways to help your
                creators self-identify. Options include{" "}
                <u
                  className="pop"
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen(e, "pre")}
                  onMouseLeave={handlePopoverClose}
                >
                  pre-enrollment
                </u>
                ,{" "}
                <u
                  className="pop"
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen(e, "onboard")}
                  onMouseLeave={handlePopoverClose}
                >
                  onboarding on set
                </u>
                , and{" "}
                <u
                  className="pop"
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => handlePopoverOpen(e, "outreach")}
                  onMouseLeave={handlePopoverClose}
                >
                  retroactive outreach
                </u>{" "}
                for post-production.
              </p>
            </AccordionDetails>

            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
              PaperProps={{
                style: { width: "100%", maxWidth: "500px" },
              }}
            >
              <Typography dangerouslySetInnerHTML={{ __html: text }} />
            </Popover>
          </Accordion>

          {FAQITEM[0].forHires.slice(5).map((item, index) => (
            <Accordion
              key={index}
              onClick={() => setCurrentOpen(item.id)}
              expanded={item.id === currentOpen}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${index}-content`}
                id={index}
              >
                <Typography className={classes.heading}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p dangerouslySetInnerHTML={{ __html: item.description }} />

                {item.points.length > 0 &&
                  item.points.map((i, index) => (
                    <ul key={index}>
                      <li dangerouslySetInnerHTML={{ __html: i.item }} />
                    </ul>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}

          <br />

          <h3>For Crew Members</h3>
          {FAQITEM[1].forCrewMembers.map((item, index) => (
            <Accordion
              key={index}
              onClick={() => setCurrentOpen(item.id)}
              expanded={item.id === currentOpen}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${index}-content`}
                id={index}
              >
                <Typography className={classes.heading}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p dangerouslySetInnerHTML={{ __html: item.description }} />

                {item.points.length > 0 &&
                  item.points.map((i, index) => (
                    <ul key={index}>
                      <li dangerouslySetInnerHTML={{ __html: i.item }} />
                    </ul>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      <SignUp faq dashboard={dashboard === "true"} />
    </Layout>
  )
}

export default FAQ
