const FAQITEM = [
  {
    forHires: [
      {
        id: 1,
        title: "What is INVOKE Insights?",
        description:
          "We are a non-profit platform that specializes in capturing the makeup of a production privately & anonymously through self-identification and in collaboration with the crew. It is inclusive to everyone regardless of your background. With anonymously aggregated information we are able to drive the larger production industry and leaders with actions on how to support crew members on set and build pipelines.",
        points: [
          {
            item:
              "The platform features a storytelling format, allowing crew to share their background and identity as well as factors that impact their production experience.",
          },
        ],
      },
      {
        id: 2,
        title: "What makes Invoke different from other platforms?",
        description:
          "Many of the diversity tracking platforms in the entertainment industry are inherently performative  and transactional, only looking to document graphs that are limited and archaic. Invoke’s platform is designed to provide transparency and accountability. Reports create not only insights but opportunities to connect with FREE THE WORK tools for proactive hiring.",
        points: [],
      },
      {
        id: 3,
        title:
          "Do I have to be a prior FTW partner to use Invoke on my productions?",
        description:
          "Invoke works with many brands, agencies, and companies to include its survey on productions.",
        points: [],
      },
      {
        id: 4,
        title: "What are the eligibility requirements?",
        description:
          "In order for Invoke to be used on a production, our team must be able to collect at least five responses from crew members to ensure anonymity. If your team is only tracking one role for a specific duration of the year, that would also require at least five responses from crew members to ensure anonymity.",
        points: [],
      },
      {
        id: 5,
        title: "How can Invoke be a part of my next production?",
        description: `Invoke operates in a couple of different ways to help your creators self-identify. Options include <u>pre-enrollment</u>, <u>onboarding on set</u>, and <u>retroactive outreach</u> for post-production.`,
        points: [
          {
            item: `<u>Pre-enrollment</u> can be utilized before a production ever begins and allows creators to complete a portion of the form and save their information for future productions using their phone number. This feature is currently in development and will be launched very soon.`,
          },
          {
            item: `<u>Onboarding on set</u> can be utilized during the production and allows for specialists to arrive with COVID officers on set at the beginning of one or multiple shoot days. This option allows for creators to ask questions and get more information about Invoke as they complete the form.`,
          },
          {
            item: `<u>Retroactive Outreach</u> can be utilized after a production has wrapped their set days and/or during post-production. This option allows specialists to contact creators virtually with a unique form link. Additionally, representatives can be made available for introductory calls for more information on the platform and access to virtual explainer materials.`,
          },
        ],
      },
      {
        id: 6,
        title: "What is the process for implementing Invoke on my production?",
        description: "",
        points: [
          {
            item: `<u>Step One</u>: Complete <a target="_blank" href="https://forms.monday.com/forms/8009b2a63bd64c9a5820090de364c316?r=use1">this form</a> to submit information about your production.`,
          },
          {
            item: `<u>Step Two:</u> The Invoke team will contact you to schedule a meeting about your goals around inclusion and current programming. This meeting will also include an overview of your production details so that we can select the best strategy for operating on your production.`,
          },
          {
            item: `<u>Step Three:</u>  Invoke specialists will prepare informational materials to share with your crew. Material formats may vary depending on the Invoke option selected.`,
          },
          {
            item: `<u>Step Four:</u> Survey is distributed to all crew for anonymous data collection.`,
          },
          {
            item: `<u>Step Five:</u> Once complete, Invoke specialists will create a report based on the findings and schedule a call to review and discuss implications and solutions.`,
          },
        ],
      },
      {
        id: 7,
        title: "What are the benefits of completing the Invoke survey?",
        description:
          "Invoke is an opportunity to identify previously underreported information on the crew experience in productions. Results we are predicting include:",
        points: [
          {
            item: `People feel seen as unique individuals, not just check boxes.`,
          },
          {
            item: `More inclusive environments on set.`,
          },
          { item: `Authentic storytelling.` },
          {
            item: `Increased accountability and the ability to set new industry-wide goals.`,
          },
        ],
      },
      {
        id: 8,
        title: "Who do I contact for questions or feedback?",
        description: `Email all inquiries to <a href="mailto:invoke@freethework.com">invoke@freethework.com</a>`,
        points: [],
      },
    ],
  },
  {
    forCrewMembers: [
      {
        id: 9,
        title: "What is INVOKE INSIGHTS?",
        description:
          "We are a non-profit platform that specializes in capturing the makeup of a production privately & anonymously through self-identification and in collaboration with the crew. It is inclusive to everyone regardless of your background. With anonymously aggregated information we are able to drive the larger production industry and leaders with actions on how to support crew members on set and build pipelines.",
        points: [
          {
            item:
              "The platform features a storytelling format, allowing crew to share their background and identity as well as factors that impact their production experience.",
          },
        ],
      },
      {
        id: 10,
        title: `Is this for me? `,
        description: `Yes! INVOKE is for all creators and crew that are hired on a project, no matter your demographic background . Our goal is to capture the makeup of the entire industry and we can't do that without everyone's participation.`,
        points: [],
      },
      {
        id: 11,
        title: `How long does it take to complete?`,
        description: `The survey takes just 2 minutes to complete.`,
        points: [],
      },
      {
        id: 12,
        title: `What if I don’t want to share my information?`,
        description: `There are options in every section to select ‘prefer not to say’ if you wish not to self-identify.`,
        points: [],
      },
      {
        id: 13,
        title: `What does the process of using Invoke look like?`,
        description: ``,
        points: [
          {
            item: `<u>Step One:</u> For each Invoke production you work on, you will receive a link to complete the survey.  You will also have the option to enroll with your phone number to save your responses for future jobs.`,
          },
          {
            item: `<u>Step Two:</u> That’s it! Tell your friends all about it!`,
          },
        ],
      },
      {
        id: 14,
        title: `What happens after I submit my information?`,
        description: `Invoke uses your submission to generate a broad report. Once we have the report, we work with productions to address results of data. After we understand the results, then we can implement programming to support positive on-set improvements.`,
        points: [],
      },
      {
        id: 15,
        title: `Who sees the reports? `,
        description: `All information on the reports are anonymous and aggregated so there is no individually identifying information available. Reports are created for hirers to understand their production environments.`,
        points: [],
      },
      {
        id: 16,
        title: `How do I know my data will be anonymous?`,
        description: `Invoke is a 501c3 nonprofit organization, and all information collected will be used solely to generate broad, anonymized reports. Reports never show any specifically identifying information and insufficient data is denoted when minimum response thresholds are not met. Info will never be shared or used for profit.`,
        points: [],
      },
      {
        id: 17,
        title: `How can I enroll?`,
        description: `You can choose to enroll using your phone number at the end of the survey to have your data saved for future productions.`,
        points: [],
      },
      {
        id: 18,
        title: `What are the benefits of completing the Invoke survey?`,
        description: `Invoke is an opportunity to identify previously underreported information on the crew experience in productions. Results we are predicting include:`,
        points: [
          {
            item: `People being seen as unique individuals, not just check boxes.`,
          },
          {
            item: `More inclusive environments on set.`,
          },
          {
            item: `Authentic storytelling.`,
          },
          {
            item: `Increased accountability and the ability to set new industry-wide goals.`,
          },
        ],
      },
      {
        id: 19,
        title: `Who do I contact for questions or feedback?`,
        description: `Email us all inquiries to <a href="mailto:invoke@freethework.com">invoke@freethework.com</a>`,
        points: [],
      },
    ],
  },
]

export default FAQITEM
